.pace-paper {
  margin: 20px auto;
  width: 90% !important;  
}

.pace-paper-content {
  padding: 16px;
  margin-bottom: 10px;
}

.pay-off {
  text-align: center;
  color: grey;
}