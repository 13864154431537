body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: gold;
}

.pace-paper {
  margin: 20px auto;
  width: 90% !important;  
}

.pace-paper-content {
  padding: 16px;
  margin-bottom: 10px;
}

.pay-off {
  text-align: center;
  color: grey;
}

.track-pace-header {
    font-weight: bold !important;
}
